'use client';

import { CalendarProvider } from '@/hooks/useCalendar';
import { DarkTheme } from '@/ui/themes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { LevaStyles } from './components/LevaStyles';
import { StyledComponentsRegistry } from './components/StyledComponentsRegistry';

const queryClient = new QueryClient();

export const Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledComponentsRegistry>
      <StyledThemeProvider theme={DarkTheme}>
        <CalendarProvider>
          <QueryClientProvider client={queryClient}>
            <LevaStyles />

            {children}
          </QueryClientProvider>
        </CalendarProvider>
      </StyledThemeProvider>
    </StyledComponentsRegistry>
  );
};
