'use client';

import { Stats } from '@react-three/drei';
import { Leva } from 'leva';
import { useTheme } from 'styled-components';

const isDevelopment = process.env.NODE_ENV === 'development';

export const LevaStyles = () => {
  const { accent } = useTheme();

  return (
    <>
      <Leva
        theme={{
          colors: {
            elevation1: 'rgba(0, 0, 0, 0.2)',
            elevation2: 'rgba(0, 0, 0, 0)',
            elevation3: 'rgba(255, 255, 255, 0.1)',
            accent1: accent,
            accent2: accent,
            accent3: accent,
            folderTextColor: 'white',
            highlight1: 'rgba(255, 255, 255, 0.5)',
            highlight2: 'rgba(255, 255, 255, 0.5)',
            highlight3: 'rgba(255, 255, 255, 0.5)',
          },
          fontSizes: {
            toolTip: '12rem',
          },
          shadows: {
            level1: 'inset 0 0 2px rgba(255, 255, 255, 0.5)',
          },
        }}
        hidden={!isDevelopment}
      />

      {isDevelopment && <Stats showPanel={0} />}
    </>
  );
};
