'use client';

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    background: black;
    color: white;
  }

  body {
    position: relative;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
  }

  input,
  textarea,
  button,
  select,
  a {
    /* Removes Android blue box on tap */
    -webkit-tap-highlight-color: transparent;
  }
` as any;
